<template>
  <div class="phone-page">
    <div class="phone-left">
      <el-form>
        <el-form-item>
          <el-select
            style="width:100%;"
            v-model="valueType"
            size="small"
            placeholder="请选择"
            clearable
          >
            <el-option label="手机号" value="phone"></el-option>
            <el-option label="邮箱" value="email"></el-option>
            <el-option label="用户名" value="uname"></el-option>
            <el-option label="昵称" value="nickname"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item>
          <el-input size="small" placeholder="请输入内容"></el-input>
        </el-form-item> -->
        <el-form-item>
          <el-button
            size="small"
            type="primary"
            style="width:100%; border-radius: 16px;"
            @click="searchFun"
            :loading="btnLoading"
          >
            查询
          </el-button>
        </el-form-item>
      </el-form>
      <div class="left-log">
        <logCom
          :logList="logList"
          title="近10条查询记录"
          @selectLog="handleClick"
        />
      </div>
    </div>
    <div class="phone-right">
      <tableComponent
        :tableData="tableList"
        :tableHead="tableHead"
        :height="pageHeight - 170"
        maxHeight="1200"
        v-loading="btnLoading"
      >
        <el-table-column fixed="right" label="操作" width="70">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small">
              查看
            </el-button>
          </template>
        </el-table-column>
      </tableComponent>
      <pagination
        class="channel-pagination-child"
        :total="total"
        margin="10px 0"
        :page-size="limit"
        :currentPage="current"
        :pagerCount="5"
        :background="false"
        :sizesArr="[20, 50, 100, 200]"
        @currentChange="currentChange"
        @sizeChange="sizeChange"
      />
    </div>
  </div>
</template>

<script>
import tableComponent from '../components/TableCom.vue'
import logCom from '../components/LogCom.vue'
import NoData from '../../components/pagination/NoData.vue'

import { logList } from '@/api/history'
export default {
  components: {
    tableComponent,
    logCom,
    NoData
  },
  data() {
    return {
      //
      valueType: '',
      checkedType: [],
      logList: [], //查询记录
      tableHead: [
        {
          label: '搜索内容',
          prop: 'target_account'
        },
        {
          label: '类型',
          prop: 'target_name'
        },
        {
          label: '状态',
          prop: 'stateName'
        },
        {
          label: '创建时间',
          prop: 'create_time'
        }
      ],

      current: 1,

      limit: 20,

      total: 0,
      tableList: []
    }
  },
  computed: {
    pageHeight() {
      return this.$store.state.windowData.height
    }
  },
  created() {
    this.getlogList()
    this.leftLogList()
  },
  methods: {
    searchFun() {
      this.current = 1
      this.getlogList()
    },
    leftLogList() {
      let obj = {
        page_index: 1,
        page_size: 10,
        target_type: ''
      }
      logList(obj).then(res => {
        this.logList = res.data.data.task_list
      })
    },
    getlogList() {
      this.btnLoading = true
      let obj = {
        page_index: this.current,
        page_size: this.limit,

        target_type: this.valueType
      }
      logList(obj)
        .then(res => {
          this.total = res.data.data.count
          this.tableList = res.data.data.task_list.map(item => {
            if (item.target_type === 'phone') {
              item.target_name = '手机号'
            } else if (item.target_type === 'email') {
              item.target_name = '邮箱'
            } else if (item.target_type === 'uname') {
              item.target_name = '用户名'
            } else if (item.target_type === 'nickname') {
              item.target_name = '昵称'
            }
            if (item.interface_state == 2 && item.state == 2) {
              item.stateName = '处理完成'
            } else {
              item.stateName = '处理中'
            }
            return item
          })
          this.btnLoading = false
        })
        .catch(() => {
          this.btnLoading = false
        })
    },
    currentChange(val) {
      this.current = val
      this.getlogList()
    },

    sizeChange(val) {
      this.current = 1
      this.limit = val
      this.getlogList()
    },
    handleClick(row) {
      this.$router.push({
        path: '/history/log/detail/' + row.search_uid,
        query: {
          target_account: row.target_account,
          interface: JSON.stringify(row.interface)
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.phone-page {
  width: 100%;
  height: calc(100vh - 79px);
  background: rgb(240, 242, 245);
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  .phone-left {
    width: 259px;
    height: 100%;
    background: #ffffff;
    border: 0px solid #000000;
    box-shadow: 0px 0px 10px 0px rgba(124, 124, 124, 0.2);
    border-radius: 5px;
    padding: 10px;
    overflow: hidden;
    ::v-deep .el-form-item {
      margin-bottom: 10px;
    }
    .left-log {
      width: 100%;
      height: calc(100% - 175px);
      overflow: hidden;
      margin-top: 30px;
    }
  }
  .phone-right {
    width: calc(100% - 270px);
    height: 100%;
    background: white;
    padding: 10px;
  }
}
::v-deep .el-collapse-item__header {
  background: #e9e9e9;
  border-bottom: 1px solid #dddddd;
}
</style>
